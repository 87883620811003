@if (!modalView) {
<h2 [innerHtml]="titlekey | messageParagraphs"></h2>
}

<div class="panel">

  @if (nodata) {
  <div class="red-font">No Financial Aid Data.</div>
  }@else {

  @if (aidYears.length > 0) {
  <div>
    <h3>Aid Year:</h3>
    <ul class="items">
      @for (aidYear of aidYears; track $index) {

      @if ((!modalView || aidYear.requirements.actions.length > 0) && displayedTerms && termIsVisible(aidYear.id)) {
      <li>
        <label class="radio-item">
          <input type="radio" (change)="selectAidYearById(aidYear.id); reloadModal(aidYear)"
            [checked]="selectedAidYearId === aidYear.id" />
          <h4>
            {{ aidYear.fromYear + "-" + aidYear.toYear }}
          </h4>
        </label>
      </li>
      }

      }
    </ul>
  </div>
  }

  @if (!modalView && showDashboardButton) {
  <hr>
  <div>
    <a [href]="studentService.context | async | contexturl: 'FINANCIAL_SERVICES_DASHBOARD'" target="_blank"
      class="btn full" [innerHTML]="'FINANCIAL_SERVICES_DASHBOARD_NAME' | messageParagraphs">
    </a>
  </div>
  }

  @if (fMessageShow) {
  <dynamic-texts [texts]="fMessage" custom-class="red-font"></dynamic-texts>
  }

  @if (showRequiredDocsParagraphs) {
  <hr>

  <dynamic-texts [texts]="'MY_FINANCIAL_AID_REQUIRED_DOCUMENTS' | messageParagraphs" custom-class="red-font">
  </dynamic-texts>
  <a [href]="studentService.context | async | contexturl: 'CAMPUS_LOGIC'"
    [innerHtml]="'CAMPUS_LOGIC' | messageParagraphs" target="_blank" class="btn full"> </a>
  }

  @if (showActionRequired) {
  <hr>

  @if (usePopover) {
  <dynamic-texts [texts]="'MY_FINANCIAL_AID_ACTION_REQUIRED' | messageParagraphs" custom-class="red-font">
  </dynamic-texts>
  <a [href]="studentService.context | async | contexturl: 'GOSOLAR_FIN_AID_MAIN_MENU'" target="_blank" class="btn full">
    My Financial Aid Status
  </a>
  }@else {
  <dynamic-texts [texts]="'MY_FINANCIAL_AID_ACTION_REQUIRED' | messageParagraphs" custom-class="bold-font">
  </dynamic-texts>
  <h4 class="red-font">
    Outstanding Requirements: {{ requiredModalActions.length }}
  </h4>
  <ol>
    @for (req of requiredModalActions; track $index) {
    <li>
      <b>{{ req.long_desc }}</b>
    </li>
    }
  </ol>
  }

  <dynamic-texts [texts]="'MY_FINANCIAL_AID_MESSAGE_PARAGRAPHS' | messageParagraphs" custom-class="red-font">
  </dynamic-texts>
  }

  @if (showFAFSA) {
  <hr>

  <dynamic-texts [texts]="yearFAFSAMessage" custom-class="red-font"></dynamic-texts>
  }

  @if (showPendingAwards || showAcceptedAwards) {
  <hr>
  }

  @if (showPendingAwards) {
  <div>
    <dynamic-texts [texts]="'MY_FINANCIAL_AID_PENDING_AWARDS' | messageParagraphs" custom-class="red-font">
    </dynamic-texts>
    <h3>Pending Awards</h3>
    <ul class="items">
      @for (award of pendingAwards; track $index) {
      <li>
        <div class="flx spread indent">
          <h4>{{ award.fund }}</h4>
          <div center>{{ award.amount }}</div>
        </div>
      </li>
      }
    </ul>
  </div>
  }

  @if (showPendingAwards && showAcceptedAwards) {
  <br />
  }

  @if (showAcceptedAwards) {
  <div>
    <dynamic-texts [texts]="'MY_FINANCIAL_AID_ACCEPTED_AWARDS' | messageParagraphs" custom-class="red-font">
    </dynamic-texts>
    <h3>Accepted Awards</h3>
    <ul class="items">
      @for (award of acceptedAwards; track $index) {
      <li>
        <div class="flx spread indent">
          <h4>{{ award.fund }}</h4>
          <div center>{{ award.amount }}</div>
        </div>
      </li>
      }
    </ul>
  </div>
  }

  @if (showPendingAwards || showAcceptedAwards) {
  <div>
    <a [href]="studentService.context | async | contexturl: 'GOSOLAR_AWARD_BY_YEAR'" target="_blank" class="btn full">
      View My Financial Aid Award
    </a>
  </div>
  }

  @if (!modalView) {
  <hr>

  <div>
    <dynamic-texts [texts]="'MY_FINANCIAL_AID_ACADEMIC_PROGRESS' | messageParagraphs"></dynamic-texts>
    <h3>Academic Progress</h3>
    <a [href]="studentService.context | async | contexturl: 'GOSOLAR_ACADEMIC_PROGRESS'" target="_blank"
      class="btn full">
      My Satisfactory Academic Progress
    </a>
  </div>
  }


  }
</div>
