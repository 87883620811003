import { Injectable } from '@angular/core';

@Injectable({ providedIn: "root" })
export class OpenService {
  reloading = false;

  constructor() { }

  public openUrl(url: string, query?: any): void {
    if (query) {
      const queryString = new URLSearchParams(Object.entries(query)).toString();
      url = queryString ? `${url}?${queryString}` : url;
    }
    window.open(url, '_blank', 'toolbar=yes,location=yes,status=yes,menubar=yes,scrollbars=yes,fullscreen=no');
  }

  public reload(): void {
    window.location.href = '';
    window.location.reload();
    this.reloading = true;
  }

  public join(baseURL: string, path: string) { // TODO: accept arbitrarily many parameters (as in RCB-Forms)
    let url: string = baseURL;
    if ('/' !== url.slice(-1) && '/' !== path[0]) { url += '/'; }
    url += path;
    return url;
  }
}
