import { ContexturlPipe } from "../../pipes/contexturl.pipe";
import { DynamicTextsComponent } from "./../dynamic-texts/dynamic-texts.component";
import { MessageParagraphsPipe } from "./../../pipes/messageParagraphs.pipe";
import { RenderedComponentsService } from "./../../services/rendered-components.service";
import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
  Output,
  EventEmitter,
  ChangeDetectorRef,
} from "@angular/core";

import { AdminEngine } from "../../models/admin-engine.model";
import { Student } from "../../models/student-transform.model";
import { AdminEngineService } from "../../services/admin-engine.service";
import { ErrorService } from "../../services/error.service";
import { OpenService } from "../../services/open.service";

import {
  FinancialAidYear,
  FinancialAidAward,
  FinancialAidRequirement,
  SemesterTerm,
} from "../../models/student-transform.model";
import { StudentService } from "../../services/student.service";
import { EnvService } from "../../services/env.service";
import { AsyncPipe } from "@angular/common";

@Component({
  standalone: true,
  selector: "financial-aid",
  templateUrl: "financial-aid.component.html",
  styleUrls: ["financial-aid.component.scss"],
  imports: [
    MessageParagraphsPipe,
    DynamicTextsComponent,
    ContexturlPipe,
    AsyncPipe,
  ],
})
export class FinancialAidComponent implements OnChanges, OnInit {
  private className = "FinancialAidComponent";

  @Input() student: Student;
  @Input() aidYear?: any;
  @Input() modalView?: boolean;
  @Output() showModal = new EventEmitter<any>();

  showDashboardButton = false;
  showRequiredDocsParagraphs = false;
  showPendingAwards = false;
  showAcceptedAwards = false;
  showFAFSA = false;
  showActionRequired = false;
  usePopover = true;
  shown = false;
  titlekey = "MY_FINANCIAL_AID_TITLE";
  fMessage: string[];
  aidYears: Array<FinancialAidYear> = [];
  fMessageShow = false;
  showAlert = false;
  selectedAidYearId: string = "";
  displayedTerms: Array<{ year: string }> = [];
  acceptedAwards: ReadonlyArray<FinancialAidAward> = [];
  pendingAwards: ReadonlyArray<FinancialAidAward> = [];
  nodata = true;

  messageFAFSA: string[];
  yearFAFSAMessage: string[] = [];
  selectedAidYear: FinancialAidYear | undefined;
  requiredDocs: ReadonlyArray<FinancialAidRequirement>;
  requiredActions: ReadonlyArray<FinancialAidRequirement>;
  requiredModalActions: ReadonlyArray<FinancialAidRequirement>;

  constructor(
    private adminEngineService: AdminEngineService,
    private errorService: ErrorService,
    private openService: OpenService,
    public studentService: StudentService,
    private renderedComponents: RenderedComponentsService,
    private ref: ChangeDetectorRef
  ) {
    this.adminEngineService
      .getKey(EnvService.DASHBOARD_APP_ID, "fin-aid-years")
      .then((terms: any) => (this.displayedTerms = terms.years || []));
  }

  ngOnInit() {
    if (this.modalView) {
      this.usePopover = false;
    }

    this.showDashboardButton = this.renderedComponents.getComponent('financial-aid-button');
  }

  async ngOnChanges(changes: SimpleChanges): Promise<void> {
    // this only detects changes to the reference to the @Inputs, it will not detect changes to inner properties of the @Inputs
    const fn: string = "?>" + this.className + "#ngOnChanges"; // tslint:disable-line:no-unused-variable
    try {
      if (changes.student.currentValue !== undefined) {
        this.aidYears = this.student.financialAid.financialAidYears;
        if (this.aidYears.length > 0) {
          this.nodata = false;
          if (this.aidYear && this.aidYear !== undefined) {
            await this.selectAidYearById(this.aidYear.id);
          } else {
            await this.selectAidYearById(this.aidYears[0].id);
          }
        } else {
          this.nodata = true;
        }
      }
    } catch (e) {
      if (!(e instanceof Error)) {
        e = new Error(e);
      }
      this.errorService.logError(e);
      if (!e.message) {
        e.message = "(No message)";
      }
      e.message = fn + "/CATCH: Error in OnChanges -- " + e.message;
      console.error(e);
    }
  }

  termIsVisible(id: string): boolean {
    return this.displayedTerms.filter((x) => x.year === id).length > 0;
  }

  async selectAidYearById(selectedAidYearId: any): Promise<void> {
    this.selectedAidYearId = selectedAidYearId;
    this.selectedAidYear = this.aidYears.find(
      (y) => y.id === selectedAidYearId
    );
    this.studentService.updateContext({
      SelectedAidYear: this.selectedAidYearId,
    });
    if (this.selectedAidYear) {
      this.acceptedAwards = this.selectedAidYear.awards.accepted || [];
      this.pendingAwards = this.selectedAidYear.awards.pending || [];
      this.requiredDocs = this.selectedAidYear.requirements.documents || [];
      this.requiredActions = this.selectedAidYear.requirements.actions || [];
    }
    this.setRequiredDocsParagraphs();
    this.ref.detectChanges();
  }

  private async setRequiredDocsParagraphs(): Promise<void> {
    const paragraphs = await this.adminEngineService.getKey(
      EnvService.DASHBOARD_APP_ID,
      "message-paragraphs"
    );
    this.messageFAFSA = paragraphs["MY_FINANCIAL_AID_FAFSA_MESSAGE"];
    if (
      this.selectedAidYear?.aidCode === "Y" &&
      this.student.spbpers_citz_code !== "A"
    ) {
      this.yearFAFSAMessage = [];
      this.yearFAFSAMessage[0] = this.messageFAFSA[0].replace(
        "(REPLACE_YEAR)",
        this.selectedAidYear.yearsDescription
      );
      this.showAlert = true;
      this.showFAFSA = true;
    } else {
      this.yearFAFSAMessage = [];
      this.showAlert = false;
      this.showFAFSA = false;
    }
    this.showRequiredDocsParagraphs =
      this.selectedAidYear && this.requiredDocs.length > 0;
    this.showAlert = this.showRequiredDocsParagraphs;
    this.showPendingAwards = this.pendingAwards.length > 0;
    this.showAcceptedAwards = this.acceptedAwards.length > 0;
    this.showActionRequired = this.requiredActions.length > 0;
    this.showAlert = this.showActionRequired || this.showAlert;

    if (this.modalView === true) {
      this.requiredModalActions = this.requiredActions.filter(
        (req) =>
          (req.statusCode === "B" ||
            req.statusCode === "I" ||
            req.statusCode === "G" ||
            req.statusCode === "R") &&
          req ===
            this.requiredActions.find(
              (action) => action.reqCode === req.reqCode
            )
      );
      this.showRequiredDocsParagraphs = false;
      this.showPendingAwards = false;
      this.showAcceptedAwards = false;
    }

    const term = this.student.studentAccounts.semesterTerms.find(
      (x: SemesterTerm) => x.fMessageCode === "Y"
    );
    if (!term) return;
    await this.adminEngineService
      .getKey(EnvService.DASHBOARD_APP_ID, "a-and-f-messages")
      .then((messages: AdminEngine) => {
        const body: any = messages;
        this.fMessage = [body.fMessage];
        this.fMessageShow = true;
      })
      .catch((err: Error) => {
        this.errorService.logError(err);
        this.errorService.showLoadingError(
          "Failed to retrieve fMessage data -- " + err.message
        );
        this.fMessage = ["ERROR: Failed to retrieve fMessage data"];
      });
  }

  reloadModal(selectedAidYear: any) {
    this.titlekey = "MY_FINANCIAL_AID_TITLE";
    if (
      (this.selectedAidYear && this.requiredDocs.length > 0) ||
      this.selectedAidYear?.requirements.actions.length > 0
    ) {
      this.titlekey = "MY_FINANCIAL_AID_TITLE_TAKE_ACTION";
      this.showModal.next(selectedAidYear);
    }
  }
}
